import React, { useEffect, useRef, useState } from "react";
import {
  Center,
  Title,
  Box,
  Text,
  List,
  Image,
  ThemeIcon,
  Stack,
  Group,
  SegmentedControl,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Checks } from "tabler-icons-react";

const DutchContent = () => {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box w="100%" sx={(theme) => ({ borderRadius: "5px" })}>
      <Stack mx="1rem" spacing="xl">
        <Center>
          <Title
            order={1}
            size={matches ? "2.5rem" : "1.8rem"}
            weight={600}
            align="center"
            sx={{ maxWidth: "800px" }}
          >
            Gratis Masterclass: Personal Branding voor Zakelijk Succes – Land
            Jouw Droombaan in 2025
          </Title>
        </Center>

        <Text
          size="lg"
          align="center"
          sx={{ maxWidth: "800px", margin: "0 auto" }}
        >
          Sta jij op met hoofdpijn bij de gedachte aan werk? Wil je die promotie
          eindelijk binnenhalen? Al een tijdje aan het solliciteren, maar je
          voelt geen vooruitgang? Dan is deze gratis masterclass echt voor jou!
        </Text>

        <Box mt="2rem">
          <Title order={3} weight={600} mb="1rem" align="center">
            Datum: 22 Januari 2025
            <br />
            Tijd: 19.00 - 19.30 (CET, Amsterdam)
          </Title>

          <Title order={3} weight={600} mb="1rem" align="center">
            Doe alleen mee als jij klaar bent om te ontdekken:
          </Title>

          <List
            spacing="md"
            size="lg"
            center
            sx={{ maxWidth: "800px", margin: "0 auto" }}
          >
            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              Hoe je een LinkedIn-profiel maakt dat de aandacht van recruiters
              trekt
            </List.Item>
            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              Simpele stappen om jouw expertise authentiek online te laten zien
            </List.Item>
            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              Hoe je opvalt in sollicitatiegesprekken met zelfvertrouwen en
              helderheid
            </List.Item>
            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              Hoe je een netwerk opbouwt met echte "advocates" die voor je gaan
            </List.Item>
            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              Actiegerichte strategieën om jouw personal brand af te stemmen op
              je droombaan
            </List.Item>
          </List>
        </Box>

        <Box mt="2rem">
          <Title
            order={2}
            weight={600}
            align="center"
            color="#7b0144"
            size={matches ? "2rem" : "1.5rem"}
          >
            2025 will be the year I will THRIVE!
          </Title>

          <Text
            size="lg"
            align="center"
            mt="1rem"
            sx={{ maxWidth: "600px", margin: "1rem auto" }}
          >
            So, what do you say? Klaar om in actie te komen? Doe je mee?
          </Text>

          <Text
            size="lg"
            align="center"
            mt="1rem"
            sx={{ maxWidth: "600px", margin: "1rem auto" }}
          >
            Meld je aan voor de masterclass en ontvang direct je online ticket
            (beperkt aantal plaatsen beschikbaar)!
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

const EnglishContent = () => {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box w="100%" sx={(theme) => ({ borderRadius: "5px" })}>
      <Stack mx="1rem" spacing="xl">
        <Center>
          <Title
            order={1}
            size={matches ? "2.5rem" : "1.8rem"}
            weight={600}
            align="center"
            sx={{ maxWidth: "800px" }}
          >
            Free Masterclass: Personal Branding for Corporate Success – Land
            Your Dream Job in 2025
          </Title>
        </Center>

        <Text
          size="lg"
          align="center"
          sx={{ maxWidth: "800px", margin: "0 auto" }}
        >
          Do you wake up with a headache at the thought of work? Ready to
          finally get that promotion? Been applying for jobs for a while but not
          seeing any results? Then this free masterclass is exactly for you!
        </Text>

        <Box mt="2rem">
          <Title order={3} weight={600} mb="1rem" align="center">
            Date: January 22nd, 2025
            <br />
            Time: 19.00 - 19.30 (CET, Amsterdam)
          </Title>

          <Title order={3} weight={600} mb="1rem" align="center">
            Join if you're ready to discover:
          </Title>

          <List
            spacing="md"
            size="lg"
            center
            sx={{ maxWidth: "800px", margin: "0 auto" }}
          >
            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              How to craft a LinkedIn profile that grabs recruiters' attention
            </List.Item>
            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              Simple steps to authentically showcase your expertise online
            </List.Item>
            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              How to stand out in interviews with confidence and clarity
            </List.Item>
            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              How to build a network of real "advocates" who've got your back
            </List.Item>
            <List.Item
              icon={
                <ThemeIcon variant="white" size={24} radius="xl">
                  <Checks color="#7b0144" size={18} />
                </ThemeIcon>
              }
            >
              Actionable strategies to align your personal brand with your dream
              job
            </List.Item>
          </List>
        </Box>

        <Box mt="2rem">
          <Title
            order={2}
            weight={600}
            align="center"
            color="#7b0144"
            size={matches ? "2rem" : "1.5rem"}
          >
            2025 will be the year I will THRIVE!
          </Title>

          <Text
            size="lg"
            align="center"
            mt="1rem"
            sx={{ maxWidth: "600px", margin: "1rem auto" }}
          >
            So, what do you say? Ready to take action? Are you in?
          </Text>

          <Text
            size="lg"
            align="center"
            mt="1rem"
            sx={{ maxWidth: "600px", margin: "1rem auto" }}
          >
            Sign up for the masterclass now and get your online ticket right
            away (limited spots available)!
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

const MasterClassContent = ({ language }) => {
  return language === "dutch" ? <DutchContent /> : <EnglishContent />;
};

// Keeping the original Form component unchanged
const Form = () => {
  const matches = useMediaQuery("(min-width: 56.25em)");
  const formContainerRef = useRef(null);

  useEffect(() => {
    if (!formContainerRef.current) return;

    const loadForm = () => {
      const formDiv = document.createElement("div");
      formDiv.className = "_form_1";
      formContainerRef.current.innerHTML = "";
      formContainerRef.current.appendChild(formDiv);

      const script = document.createElement("script");
      script.src = "https://tsnatee.activehosted.com/f/embed.php?id=1";
      script.charset = "utf-8";
      script.async = true;

      const handleScriptLoad = () => {
        if (window._form_callback) {
          window._form_callback();
        }

        setTimeout(() => {
          const allForms = document.querySelectorAll("._form_1");
          allForms.forEach((form) => {
            if (!formContainerRef.current.contains(form)) {
              form.parentElement?.remove();
            }
          });
        }, 100);
      };

      script.addEventListener("load", handleScriptLoad);
      document.body.appendChild(script);

      return () => {
        script.removeEventListener("load", handleScriptLoad);
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      };
    };

    const cleanup = loadForm();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof HTMLElement) {
              const duplicateForms = node.querySelectorAll("._form_1");
              duplicateForms.forEach((form) => {
                if (!formContainerRef.current.contains(form)) {
                  form.parentElement?.remove();
                }
              });
            }
          });
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      cleanup();
      observer.disconnect();
    };
  }, []);

  return (
    <Box
      id="calendar"
      mx="auto"
      p={20}
      w={matches ? 700 : "100%"}
      ref={formContainerRef}
    />
  );
};

const MasterClassPage = () => {
  const matches = useMediaQuery("(min-width: 56.25em)");
  const [language, setLanguage] = useState("dutch");

  return (
    <Box pb={20}>
      <Center>
        <Box w="14em" mt="2em">
          <SegmentedControl
            value={language}
            onChange={(value) => setLanguage(value)}
            fullWidth
            withItemsBorders={false}
            radius="xl"
            data={[
              { label: "🇳🇱 Nederlands", value: "dutch" },
              { label: "🇬🇧 English", value: "english" },
            ]}
            size="xs"
          />
        </Box>
      </Center>
      <Box mx="auto" px={20} mt={20}>
        <Group
          align="flex-start"
          spacing={40}
          grow={matches}
          sx={{
            flexDirection: matches ? "row" : "column",
          }}
        >
          <Box sx={{ flex: "1" }}>
            <MasterClassContent language={language} />
          </Box>
          <Box
            mx="auto"
            sx={{
              flex: matches ? "0 0 40%" : "1",
              maxWidth: matches ? "800px" : "100%",
              width: "100%",
            }}
          >
            <Box
              mx="auto"
              p={20}
              sx={{
                width: "100%",
                maxWidth: "500px",
                "@media (max-width: 56.25em)": {
                  maxWidth: "400px",
                },
              }}
            >
              <Image
                src="/masterclass.jpg"
                sx={{
                  img: {
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  },
                }}
              />
            </Box>
            <Form />
          </Box>
        </Group>
      </Box>
    </Box>
  );
};

export default MasterClassPage;
