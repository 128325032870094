import React, { useRef, useState } from "react";
import {
  Center,
  Title,
  Text,
  Box,
  Button,
  List,
  ThemeIcon,
  Blockquote,
  SegmentedControl,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

import { NavLink } from "react-router-dom";

import { Checks } from "tabler-icons-react";

import Contact from "./ContactForm";

function BrandBrunchHero() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
      <video
        style={{ width: "100%", height: "auto", display: "block" }} // Ensures video is responsive; display:block removes unnecessary space
        autoPlay
        playsInline
        loop
        muted // Recommended for autoplaying videos in web contexts
      >
        <source src="/brandbrunch_video.mp4" type="video/mp4" />
        Your browser does not support video.
      </video>
    </Box>
  );
}

function BrandBrunchDutch1() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Title align="center" weight={600} order={3}>
        Plan jouw 1-1 Personal Branding sessie:{" "}
        <Text span color="bright-pink" weight="700">
          Dé Brand Brunch!
        </Text>
      </Title>

      <Center>
        <Button
          mt="1em"
          component={NavLink}
          to="/contact"
          key="contact"
          bg="#fada01"
        >
          Klik hier om van start te gaan!
        </Button>
      </Center>

      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        Wat is de Brand Brunch?
      </Title>
      <Text mt={10}>
        De Brand Brunch is een intensieve, transformerende één-op-één sessie van
        3 uur, ontworpen om jouw personal brand, carrière of side hustle naar
        een hoger niveau te tillen. Deze sessie is perfect voor jou als...
      </Text>

      <List
        spacing="sm"
        mt={10}
        size="md"
        icon={
          <ThemeIcon variant="white" size={24} radius="xl">
            <Checks color="#7b0144" />
          </ThemeIcon>
        }
      >
        <List.Item>
          je niet weet wat je over jezelf moet posten voor je carrière, vooral
          op LinkedIn.
        </List.Item>
        <List.Item>
          je moeite hebt om je diverse vaardigheden en interesses om te zetten
          in een samenhangend persoonlijk brand.
        </List.Item>
        <List.Item>
          je vastzit en onzeker bent over je volgende carrièrestap.
        </List.Item>
      </List>

      <Text weight={600} mt={10}>
        Zeg je: “Ja, dit ben ik!" bij een van de drie punten of allemaal? Dan is
        de Brand Brunch voor jou gemaakt.
      </Text>

      <Text mt={20} weight={500} align="center">
        Tsnatee’s proactieve aanpak zorgt ervoor dat je praktische ervaring
        opdoet met onmiddellijke, concrete resultaten.
      </Text>
      {/* <Text c="#7b0144" size="1.5em" weight={700} align="center" mt={20} italic>
        “You’ll be put to work to make it work for YOU! - Tsnatee”
      </Text> */}
      <Box
        sx={(theme) => ({
          padding: "10px",
          fontStyle: "italic",
          borderRadius: "5px",
          color: "white",
        })}
      >
        <Blockquote
          color="bright-pink"
          radius="lg"
          iconSize={100}
          cite="Tsnatee"
        >
          <Text weight="700" size="1em">
            You’ll be put to work to make it work for YOU!
          </Text>
        </Blockquote>
      </Box>

      <Box
        sx={(theme) => ({
          borderRadius: "5px",
        })}
        c="black"
        w="100%"
        p="2em"
        bg="#FBF4EA"
      >
        <Title c="black" align="center" weight={600} order={4} mt={5}>
          Wat maakt de Brand Brunch speciaal?
        </Title>
        <Text mt={10}>
          De Brand Brunch is uniek door zijn diepe, boeiende en leuke aanpak.
          Dit is wat Tsnatee onderscheidt:
        </Text>

        <List
          spacing="sm"
          mt={10}
          size="md"
          icon={
            <ThemeIcon variant="white" size={24} radius="xl">
              <Checks color="black" />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text c="black">
              <strong>Onmiddellijke implementatie:</strong> tijdens de sessie
              word je geholpen met het bedenken en creëren van content wat bij
              je past.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="black">
              <strong>Brand statement maken:</strong> Samen stellen we een
              overtuigende brand statement op en updaten we je LinkedIn-profiel
              ter plekke.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="black">
              <strong>Real-time Begeleiding:</strong> Je krijgt real-time
              begeleiding over hoe je jezelf en je brand zelfverzekerd kunt
              presenteren online en offline.
            </Text>
          </List.Item>
        </List>
      </Box>

      <Title c="black" align="center" weight={600} order={4} mt={30}>
        Wat kun je verwachten?
      </Title>

      <List
        spacing="sm"
        mt={10}
        size="md"
        icon={
          <ThemeIcon variant="white" size={24} radius="xl">
            <Checks color="#7b0144" />
          </ThemeIcon>
        }
      >
        <List.Item>
          <strong>30-Minuten Oriëntatiegesprek:</strong> We bespreken je huidige
          brand en stellen duidelijke doelen voor de sessie.
        </List.Item>
        <List.Item>
          <strong>Voorbereiding Werkboek:</strong> Voor de sessie ontvang je
          jouw eigen werkboek zodat we op basis van jouw antwoorden een plan
          kunnen maken voor jouw persoonlijke Brand Brunch.
        </List.Item>
        <List.Item>
          <strong>360° Online Brand analyse:</strong> Tijdens de sessie ontvang
          je 360 graden feedback op jouw online brand.
        </List.Item>
        <List.Item>
          <strong>2,5 Uur Branding Sessie:</strong> Neem deel aan strategische
          oefeningen om je doelen en volgende stappen te verduidelijken.
        </List.Item>
        <List.Item>
          <strong>LinkedIn profiel aanpassen:</strong> Ontvang begeleiding om je
          LinkedIn-profiel af te stemmen op je carrière ambities, inclusief live
          updates tijdens de sessie.
        </List.Item>
      </List>
    </Box>
  );
}

function BrandBrunchDutch2() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box
      sx={(theme) => ({
        borderRadius: "5px",
      })}
      bg="#7b0144"
      mt="2em"
      w="100%"
      c="white"
    >
      <Box w={matches ? "700px" : "100%"} p="2em" mx="auto">
        {/* <Title c="white" align="center" weight={600} order={4}>
          Speciale Zomeraanbieding: Bespaar 25% deze zomer!
        </Title> */}

        <List
          spacing="sm"
          mt={10}
          size="md"
          icon={
            <ThemeIcon variant="white" size={24} radius="xl">
              <Checks color="white" />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text c="white">
              <strong>Brand Brunch sessie voor slechts €899</strong>
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Gratis Brunch:</strong> Geniet van een heerlijke maaltijd
              terwijl je aan je brand werkt.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Extra Follow-Up Gesprek van 30 minuten:</strong> Krijg
              extra begeleiding om je succes te verzekeren.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Career Journal Cadeau:</strong> Een speciaal cadeau om je
              voortgang en toekomstige plannen bij te houden.
            </Text>
          </List.Item>
        </List>
      </Box>
    </Box>
  );
}

function BrandBrunchDutch3() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        Hoe kun je jouw Brand Brunch boeken?
      </Title>

      <Text mt={10}>
        <strong>
          Stap 1: Plan een één-op-één oriëntatiegesprek met Tsnatee
        </strong>
      </Text>
      <Text mt={5}>
        Plan een één-op-één oriëntatiegesprek met Tsnatee zodat jullie elkaar
        kunnen leren kennen. Tijdens dit gesprek komen jouw doelen en wensen aan
        bod, en zal Tsnatee op basis van dit gesprek een plan maken voor jouw op
        maat gemaakte Brand Brunch.
      </Text>

      <Text mt={10}>
        <strong>Stap 2: Plan gezamenlijk jouw Brand Brunch</strong>
      </Text>
      <Text mt={5}>
        Tijdens het oriëntatiegesprek zullen jullie gezamenlijk jouw Brand
        Brunch inplannen.
      </Text>

      <Center>
        <Button
          mt="1em"
          component={NavLink}
          to="/contact"
          key="contact"
          bg="#fada01"
        >
          Klik hier om van start te gaan!
        </Button>
      </Center>

      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        Wat zeggen anderen over de Brand Brunch?
      </Title>

      <Box
        sx={(theme) => ({
          padding: "10px",
          fontStyle: "italic",
          borderRadius: "5px",
          color: "white",
        })}
      >
        <Blockquote color="bright-pink" radius="lg" iconSize={100} mt="xl">
          <Text align="center" italic>
            "De Brand Brunch met Tsnatee was een geweldige investering! Het was
            een geweldige manier om het jaar te beginnen, vooral als je je
            persoonlijke brand wilt versterken. Ze weet de juiste vragen te
            stellen om de juiste informatie uit je te halen, en als iets niet
            werkt, is ze niet bang om te zeggen 'Laten we alles afbreken en
            opnieuw beginnen'!"
          </Text>
          <Text mt="1em" align="center" italic>
            "Wat je moet begrijpen als je kiest voor de Brand Brunch of een van
            haar andere diensten, is dat het helemaal draait om wat JIJ uit de
            tijd met haar wilt halen. Wees eerlijk, wees open en haal het beste
            uit de waardevolle tijd die je besteedt om je brand/leven te
            verbeteren met Tsnatee."
          </Text>

          <Text align="center" mt="1em" weight="600">
            Solange Wilson
          </Text>
        </Blockquote>
      </Box>
    </Box>
  );
}

function BrandBrunchEnglish1() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Title align="center" weight={600} order={3}>
        Schedule Your 1-1 Personal Branding Session:{" "}
        <Text span color="bright-pink" weight="700">
          THE Brand Brunch!
        </Text>
      </Title>

      <Center>
        <Button
          mt="1em"
          component={NavLink}
          to="/contact"
          key="contact"
          bg="#fada01"
        >
          CLICK HERE TO START TODAY!
        </Button>
      </Center>

      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        What is the Brand Brunch?
      </Title>
      <Text mt={10}>
        The Brand Brunch is an intensive, transformative 3-hour one-on-one
        session designed to elevate your personal brand, career, or side hustle.
        This session is perfect for you if:
      </Text>

      <List
        spacing="sm"
        mt={10}
        size="md"
        icon={
          <ThemeIcon variant="white" size={24} radius="xl">
            <Checks color="#7b0144" />
          </ThemeIcon>
        }
      >
        <List.Item>
          You're unsure about what to post about yourself for your career,
          especially on LinkedIn.
        </List.Item>
        <List.Item>
          You struggle to articulate your diverse skills and interests into a
          cohesive personal brand.
        </List.Item>
        <List.Item>
          You're feeling stuck and uncertain about your next career move.
        </List.Item>
      </List>

      <Text weight={600} mt={10}>
        If you answered YES to any of these challenges, the Brand Brunch is made
        for you.
      </Text>

      <Text mt={20} weight={500} align="center">
        Tsnatee's proactive approach ensures that you gain practical experience
        with immediate, tangible results.
      </Text>

      <Box
        sx={(theme) => ({
          padding: "10px",
          fontStyle: "italic",
          borderRadius: "5px",
          color: "white",
        })}
      >
        <Blockquote
          color="bright-pink"
          radius="lg"
          iconSize={100}
          cite="Tsnatee"
        >
          <Text weight="700" size="1em">
            You'll be put to work to make it work for YOU!
          </Text>
        </Blockquote>
      </Box>

      <Box
        sx={(theme) => ({
          borderRadius: "5px",
        })}
        c="black"
        w="100%"
        p="2em"
        bg="#FBF4EA"
      >
        <Title c="black" align="center" weight={600} order={4} mt={5}>
          What Makes the Brand Brunch Special?
        </Title>
        <Text mt={10}>
          The Brand Brunch is unique due to its deep, engaging, and enjoyable
          approach. Here's what sets us apart:
        </Text>

        <List
          spacing="sm"
          mt={10}
          size="md"
          icon={
            <ThemeIcon variant="white" size={24} radius="xl">
              <Checks color="black" />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text c="black">
              <strong>Immediate Implementation:</strong> We help you create and
              post content during the session that reflects your brand.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="black">
              <strong>Crafting Your Brand Statement:</strong> Together, we'll
              develop a compelling brand statement and update your LinkedIn
              profile on the spot.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="black">
              <strong>Real-Time Guidance:</strong> Receive real-time guidance on
              how to confidently present yourself and your brand.
            </Text>
          </List.Item>
        </List>
      </Box>

      <Title c="black" align="center" weight={600} order={4} mt={30}>
        What Can You Expect?
      </Title>

      <List
        spacing="sm"
        mt={10}
        size="md"
        icon={
          <ThemeIcon variant="white" size={24} radius="xl">
            <Checks color="#7b0144" />
          </ThemeIcon>
        }
      >
        <List.Item>
          <strong>30-Minute Orientation Call:</strong> Discuss your current
          brand position and set clear goals for the session.
        </List.Item>
        <List.Item>
          <strong>Preparation Workbook:</strong> Engage in self-reflection and
          preparatory exercises before the session.
        </List.Item>
        <List.Item>
          <strong>360° Online Brand Analysis:</strong> Evaluate the impact and
          perception of your current brand.
        </List.Item>
        <List.Item>
          <strong>2.5 Hours of Focused Session:</strong> Participate in
          strategic exercises to clarify your goals and next steps.
        </List.Item>
        <List.Item>
          <strong>LinkedIn Profile Revamp:</strong> Receive guidance to align
          your LinkedIn profile with your career ambitions, including live
          updates during the session.
        </List.Item>
      </List>
    </Box>
  );
}

function BrandBrunchEnglish2() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box
      sx={(theme) => ({
        borderRadius: "5px",
      })}
      bg="#7b0144"
      mt="2em"
      w="100%"
      c="white"
    >
      <Box w={matches ? "700px" : "100%"} p="2em" mx="auto">
        <List
          spacing="sm"
          mt={10}
          size="md"
          icon={
            <ThemeIcon variant="white" size={24} radius="xl">
              <Checks color="white" />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Text c="white">
              <strong>Brand Brunch session for just €899</strong>
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Complimentary Brunch:</strong> Enjoy a delicious meal
              while working on your brand.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Additional 30-Minute Follow-Up Call:</strong> Receive
              extra support to ensure your success.
            </Text>
          </List.Item>
          <List.Item>
            <Text c="white">
              <strong>Career Journal Gift:</strong> A special gift to track your
              progress and future plans.
            </Text>
          </List.Item>
        </List>
      </Box>
    </Box>
  );
}

function BrandBrunchEnglish3() {
  const matches = useMediaQuery("(min-width: 56.25em)");

  return (
    <Box mx="auto" p={20} w={matches ? "700px" : "100%"}>
      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        How to Book Your Brand Brunch?
      </Title>
      <Text mt={10}>
        <strong>
          Step 1: Plan your one-on-one orientation call with Tsnatee
        </strong>
      </Text>
      <Text mt={5}>
        Plan your one-on-one orientation call with Tsnatee to get to know each
        other. During the orientation call we'll discuss your goals, interests,
        passions and desires. Based on your response Tsnatee will create a
        tailor-made Brand Brunch that will be a perfect fit for your desires.
      </Text>
      <Text mt={10}>
        <strong>Step 2: Plan a date for your personal Brand Brunch</strong>
      </Text>
      <Text mt={5}>
        During the orientation call you and Tsnatee will schedule the Brand
        Brunch date that fits your schedule and location preference.
      </Text>
      <Center>
        <Button
          mt="1em"
          component={NavLink}
          to="/contact"
          key="contact"
          bg="#fada01"
        >
          CLICK HERE TO START TODAY!
        </Button>
      </Center>

      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        What do others say about the Brand Brunch?
      </Title>
      <Box
        sx={(theme) => ({
          padding: "10px",
          fontStyle: "italic",
          borderRadius: "5px",
          color: "white",
        })}
      >
        <Blockquote color="bright-pink" radius="lg" iconSize={100} mt="xl">
          <Text align="center" italic>
            "The Brand Brunch with Tsnatee was a fantastic investment! It was a
            great way to start the year, especially if you want to strengthen
            your personal brand. She knows how to ask the right questions to get
            the right information out of you, and if something isn't working,
            she's not afraid to say, 'Let's break it down and start over!'"
          </Text>
          <Text mt="1em" align="center" italic>
            "What you need to understand when choosing the Brand Brunch or any
            of her other services is that it's all about what YOU want to get
            out of your time with her. Be honest, be open, and make the most of
            the valuable time you spend improving your brand/life with Tsnatee."
          </Text>
          <Text align="center" mt="1em" weight="600">
            Solange Wilson
          </Text>
        </Blockquote>
      </Box>

      <Title c="#7b0144" align="center" weight={600} order={4} mt={30}>
        So, Are you Ready to Transform Your Summer?
      </Title>
      <Text mt={10}>
        Don't let the quieter summer months hold you back. We'll share 3 more
        reasons to invest in your future now:
      </Text>
      <List
        spacing="sm"
        mt={10}
        size="md"
        icon={
          <ThemeIcon variant="white" size={24} radius="xl">
            <Checks color="#7b0144" />
          </ThemeIcon>
        }
      >
        <List.Item>
          <strong>Reflect and Refocus:</strong> Use the slower season to
          evaluate your career goals and personal brand.
        </List.Item>
        <List.Item>
          <strong>LinkedIn Revamp:</strong> Update your LinkedIn profile to
          truly reflect the real you—with your ambitions in mind—and stand out
          to employers, recruiters, or clients.
        </List.Item>
        <List.Item>
          <strong>Plan Your Next Steps:</strong> Develop a clear personal brand
          strategy for your career.
        </List.Item>
      </List>
      <Text mt={10}>
        So, if you're reading this, plan your free orientation call with Tsnatee
        quickly via Google Meet to ask your questions directly.
      </Text>
      <Center>
        <Button
          mt="1em"
          component={NavLink}
          to="/contact"
          key="contact"
          bg="#fada01"
        >
          CLICK HERE TO START TODAY!
        </Button>
      </Center>
    </Box>
  );
}

function BrandBrunchContent({ language }) {
  return language == "dutch" ? (
    <>
      <BrandBrunchDutch1 />
      <BrandBrunchDutch2 />
      <BrandBrunchDutch3 />
    </>
  ) : (
    <>
      <BrandBrunchEnglish1 />
      <BrandBrunchEnglish2 />
      <BrandBrunchEnglish3 />
    </>
  );
}

function BrandBrunchPage() {
  const [language, setLanguage] = useState("dutch");

  return (
    <>
      <BrandBrunchHero />
      <Center>
        <Box w="14em" mt="2em">
          <SegmentedControl
            value={language}
            onChange={(value) => setLanguage(value)}
            fullWidth
            withItemsBorders={false}
            radius="xl"
            data={[
              { label: "🇳🇱 Nederlands", value: "dutch" },
              { label: "🇬🇧 English", value: "english" },
            ]}
            size="xs"
          />
        </Box>
      </Center>
      <BrandBrunchContent language={language} />
      <Contact />
    </>
  );
}

export default BrandBrunchPage;
